<template>
  <div 
    :class="[
      'wish-list-comp', 
      { 'wish-list-comp__loading': pullDisabled },
      { 'wish-list-comp__edit': selectState },
    ]"
  >
    <template v-if="productList.length">
      <div
        v-infinite-scroll="loadMore"
        class="wish-list-comp__list j-da-event-box"
        :class="disableSimilarAdd && config.showItemV3 && 'wish-products-comp-drawer'"
        :style="listStyle"
        infinite-scroll-disabled="pullDisabled"
        infinite-scroll-nodata="doneData"
        :infinite-scroll-distance="selectState ? 0 : infiniteScrollDistance"
        :data-active-from="config.sa && config.sa.activity_from"
        :da-sa-name="config.sa.name" 
        data-poskey="PromotionalBelt,UserBehaviorLabel,RankingList,NewSheinClub"
      >
        <template v-if="column === 2 && showWaterFallStyle">
          <WaterFall
            v-if="productList && productList.length"
            class="product_list_use_new_expose"
            :items="productList"
          >
            <template #default="{ item, index }">
              <WishGroupProductItem
                :ref="`WishProductItem_${item.goods_id}`"
                :key="`WishProductItem_${item.goods_id}`"
                :similar-recommend-data="similarRecommendData"
                :item="item"
                :index="index"
                :column="column"
                :config="config"
                :in-water-fall="true"
                :language="language"
                :is-crop-card="isCropCard"
                :similar-popup-class="similarPopupClass"
                :select-state="selectState"
                :select-status="!!item.inGroup || selectItemsGoods.includes(item.goods_id)"
                :show-collect-num="showCollectNum"
                :disable-similar-add="disableSimilarAdd"
                :save-total="total"
                :handle-long-click="handleLongClick"
                :item-select="itemSelect"
                :shein-club-info="sheinClubInfo"
                :item-mounted="itemMounted"
                :handle-open-quick-add="handleOpenQuickAdd"
                :handle-add-board="handleAddBoard"
                :click-delete="clickDelete"
                :click-item="clickItem"
                :recommend-popup-good="recommendPopupGood"
                :list-abt-result="listAbtResult"
                :show-feedback-recommend="showFeedbackRecommend"
                :feedback-rec-ccc-config="feedbackRecConfig"
                @openQuickAdd="handleOpenQuickAdd"
                @update:similarPopupClass="updateSimilarPopupClass"
                @recPopupMounted="showWishFeedbackRecPopup"
                @operateInRecPopup="$emit('operateInRecPopup')"
              />
            </template>
          </WaterFall>
        </template>
        <template v-else>
          <WishGroupProductItem
            v-for="(item, index) in productList"
            :ref="`WishProductItem_${item.goods_id}`" 
            :key="item.goods_id"
            :similar-popup-class="similarPopupClass"
            :similar-recommend-data="similarRecommendData"
            :item="item"
            :index="index"
            :is-crop-card="isCropCard"
            :column="column"
            :shein-club-info="sheinClubInfo"
            :config="config"
            :language="language"
            :select-state="selectState"
            :select-status="!!item.inGroup || selectItemsGoods.includes(item.goods_id)"
            :show-collect-num="showCollectNum"
            :recommend-popup-good="recommendPopupGood"
            :disable-similar-add="disableSimilarAdd"
            :save-total="total"
            :handle-long-click="handleLongClick"
            :item-select="itemSelect"
            :item-mounted="itemMounted"
            :handle-open-quick-add="handleOpenQuickAdd"
            :handle-add-board="handleAddBoard"
            :click-delete="clickDelete"
            :click-item="clickItem"
            :list-abt-result="listAbtResult"
            @update:similarPopupClass="updateSimilarPopupClass"
          />
        </template>

        <!-- 一行三图，一行不足三个时填充 -->
        <div 
          v-if="column === 3 && productList.length % 3 !== 0"
          class="wish-list-comp__item-fill"
        ></div>
      </div>

      <s-loading
        :show="pullDisabled"
        type="pullup"
      />

      <template v-if="showSelect">
        <AllSelect
          v-show="selectState"
          v-model="allStateSign"
          :type="selectType"
          :language="language"
          :selected-num="selectItemsGoods.length"
          :is-delete="isBatchDelete"
          :disabled="selecteDisabled"
          @change="handleAllSelect"
          @onAdd="selectAddBorad"
          @onCopy="hanldeCopyItems"
          @onDelete="deleteSelectItems"
        />
      </template>
    </template>
    <ProductFilterEmpty 
      v-else-if="showFilterEmpty && empty"
      :language="language"
      :constant="constantData"
      @clickReset="clickReset"
    />
  </div>
</template>

<script>
import infiniteScroll from '@sheinfe/vue-infinite-scroll'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent, nextTick, defineAsyncComponent } from 'vue'
/* components */ 
import WishGroupProductItem from './WishGroupProductItem.vue'
import ProductFilterEmpty from './ProductFilterEmpty.vue'
import { getImageRatio } from 'public/src/pages/goods_detail_v2/utils/common.js'
// import { getQuickAddCartInstance, canUseNewQuickAdd, plugins } from 'public/src/pages/components/product/quick_add_v3/common.js' // 下沉快加车
import { getQuickAddCartInstance, plugins } from 'public/src/pages/components/product/quick_add_v3/common.js' // 下沉快加车
import { QuickAddCartState } from 'public/src/pages/components/product/quick_add_v3/plugins/syncGlobalData/index.js'

/* utils */ 
import AllSelectTool from './utils/AllSelectTool'
import GoodsInfoFormat from './utils/goodsInfoFormat'
import analysis from './analysis.js'
const { GB_cssRight, LAZY_IMG, IS_RW, lang, langPath, LAZY_IMG_PIXEL, LAZY_IMG_SOLID_COLOR, PUBLIC_CDN, IMG_LINK, WISHLIST_ABT_COMPLETE = 'off' } = gbCommonInfo
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import schttp from 'public/src/services/schttp'
// import { debounce } from '@shein/common-function'

/* minxins */ 
import similarItemAddRecMixin from 'public/src/pages/user_wishlist/mixin/similarItemAddRecMixin'
import wishfeedbackRecMixin from 'public/src/pages/user_wishlist/mixin/wishFeedbackRecMixin.js'

/* recommend pop */
import { getFeedbackRecDataInSession, setFeedbackRecDataInSession, getFeedbackRecInSession } from 'public/src/pages/product_list_v2/js/utils.js'
import { getFeedBackListGoods } from 'public/src/pages/product_list_v2/components/CardRecommend/hooks/useFeedBackApi'
import { showByLocalStorage } from 'public/src/pages/product_list_v2/components/CardRecommend/util'

export default defineComponent({
  name: 'WishListProducts',
  components: {
    WishGroupProductItem,
    ProductFilterEmpty,
    AllSelect: defineAsyncComponent(() => import(/* webpackChunkName: "wish-product" */  './AllSelect.vue')),
    WaterFall: defineAsyncComponent(() => import(/* webpackChunkName: "water-fall" */  'public/src/pages/components/product/WaterFall.vue')),
    SLoading,
  },
  directives: {
    infiniteScroll,
  },
  mixins: [similarItemAddRecMixin, wishfeedbackRecMixin],
  emits: [
    'operateInRecPopup',
    'afterPage',
    'cancelSelect',
    'loadMore',
    'longClick',
    'afterAddBorad',
    'afterDelete',
    'clickGoods',
    'afterBatchDelete',
    'dropCartBagImg',
    'reset'
  ],
  props: {
    column: {                    // 一行几图（一图、两图、三图）
      type: Number,
      default: 2
    },
    products: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    similarRecommendData: {
      type: Object,
      default: () => ({})
    },
    doneData: {                 // 加载完所有商品列表（不在请求下一页数据）
      type: Boolean,
      default: true
    },
    showSelect: Boolean,        // 选择器
    selectState: Boolean,       // 编辑状态
    selectType: {               // 选择器类型 add || copy
      type: String,
      default: () => 'add'
    },
    selecteDisabled: Boolean,
    isBatchDelete: {            // 展示选择器批量删除按钮
      type: Boolean,
      default: true
    },
    
    groupId: {                  // 分组ID
      type: [String, Number],
      default: '',
    },
    showCollectNum: Boolean,    // 显示收藏数（一行一图）
    showFilterEmpty: {          // 空态页
      type: Boolean,
      default: () => true
    },
    goodsInfoServer: {
      type: Boolean,
      default: () => true
    },
    showWaterFallStyle: { // 列表使用瀑布流风格布局
      type: Boolean,
      default: false
    },
    disableSimilarAdd: { // 给同类推荐半屏列表用， 禁止半屏列表内二次触发同类推荐弹窗
      type: Boolean,
      default: false
    },
    waterFallSsr: { // 给同类推荐半屏列表用，解决waterFall组件默认两行6个，导致半屏里元素定位到对应商品坑位深度 底色标亮功能不对
      type: Object,
      default: () => ({
        columns: 2,
        itemQuantity: 6
      })
    },
    useFrom: { // 标识该组件的父级来源，这里是给同类推荐半屏列表用，解决加车相关埋点上报问题
      type: String,
      default: ''
    },
    sheinClubInfo: {
      type: Object,
      default: () => ( {} )
    },
    nextPage: {
      type: Boolean,
      default: false
    },
    isCropCard: {
      type: Boolean,
      default: false
    },
    // 用于区分是哪个页面引用了该文件
    useScene: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      constantData: {
        GB_cssRight,
        LAZY_IMG,
        IS_RW,
        lang,
        langPath,
        LAZY_IMG_PIXEL,
        LAZY_IMG_SOLID_COLOR,
        PUBLIC_CDN,
        IMG_LINK
      },
      scenes: 'WishlistsoldoutSimilar',
      listAbtResult: {},
      similarPopupClass: {},
      productList: [],
      goodsItemInfo: null,
      allSelectTool: {},
      allStateSign: false,
      selectItemsGoods: [],
      selectLimitNum: 100,
      empty: false,
      pullDisabled: false,
      total: Number.isInteger(+sessionStorage.getItem('wishlistTotal')) ? +sessionStorage.getItem('wishlistTotal') : 0,
      showFeedbackRecommend: false,
      feedbackRecConfig: {},
      recommendPopupGood: [],
      onLineListened: false,
    }
  },
  computed: {
    infiniteScrollDistance() {
      return Math.floor((window?.innerWidth || 375) * 0.75 * 2)
    },

    /** 一行两图的商品项v3组件 */
    isTwoColItemV3 () {
      return this.column === 2 && this.config.showItemV3
    },

    listStyle () {
      if(this.disableSimilarAdd) {
        return
      }
      return this.isTwoColItemV3 ?
        { background: '#f6f6f6', padding: '0 0.16rem' } :
        { padding: '0 0.32rem' }
    },
  },
  watch: {
    products: {
      async handler(newVal) {
        if (this.goodsInfoServer && !this.goodsItemInfo) { 
          this.goodsItemInfo = new GoodsInfoFormat(this.language, this.config) // 商品项组件格式数据
        }
        this.productList = this.goodsItemInfo ? await this.goodsItemInfo.use(newVal) : newVal
        this.empty = this.productList.length < 1 // 解决闪动
        this.handleSelectItems()
        nextTick(() => {
          this.pullDisabled = false 
          this.$emit('afterPage')
        })
      },
      immediate: true
    },
    selectState(value) {
      if (!value) {
        this.clearSelect()
      }
    }
  },
  created() {
    this.disableSimilarAdd && this.setDaSaInfo()
    // this.quickAddInstance = null
  },
  // unmounted() {
  //   this.quickAddInstance = null
  // },
  async mounted() {
    this.allSelectTool = new AllSelectTool({ limitNum: this.selectLimitNum })
    const { Similaritems, WishlistsoldoutSimilar, WishlistSimilarcomparison, WishlistReco, WishlistRecoPopup, RealtimeFeedbackJson } = await getUserAbtData()
    this.showWishlistSimilar = WISHLIST_ABT_COMPLETE === 'on' ? true : WishlistsoldoutSimilar?.p?.ShowWishlistSimilar === 'show'
    this.showWishlistSimilarType = WishlistSimilarcomparison?.p?.Similarcomparison
    this.listAbtResult = { Similaritems, WishlistsoldoutSimilar, WishlistSimilarcomparison, WishlistReco, WishlistRecoPopup, RealtimeFeedbackJson }
    // 优化逻辑，分组选择列表和分享落地页不需要下面的功能，所以不要请求了，算是优化历史代码
    // 增加个人中心的收藏和足迹页过滤，那边也不需要这两个功能，所以也不请求了
    let filterScene = ['group_share', 'group_select', 'recent_wishlist', 'user_wishlist']
    if(filterScene.includes(this.useScene)) {
      return 
    }
    this.getFeedbackRecommend()
    this.getFeedbackRecConfig()
  },
  methods: {
    // 处理半屏弹窗中加车相关的埋点, recommendSimilarAddDrawer也有掉(重置activity_from)
    setDaSaInfo(flag) {
      if(this.useFrom === 'similar_items_added') {
        this.config.sa.activity_from = 'similar_items_added'
        this.config.sa.from = 'similar_items_added'
      }
      // 处理半屏列表关闭后，当前列表埋点问题
      if(flag) {
        this.config.sa.activity_from = 'wishlist'
        this.config.sa.from = 'wishlist'
      }
    },
    // 外部调用 重置曝光
    resetExpose() {
      analysis.resetExpose(this.config.sa?.activity_from)
    },

    /* 设置组件属性用于`埋点上报 */
    itemMounted(vm) {
      if (this.column == 1) return // 一行一图已绑定
      vm.setElAttr?.()
    },

    // 处理选择商品相关
    handleSelectItems() {
      this.selectItemsGoods = this.allSelectTool.updateSelectGoods?.(this.productList) || []

      if (this.productList.length === 0 && this.selectState) {
        this.$emit('cancelSelect') // 取消编辑状态
      }

      if (this.allStateSign && this.productList.length !== this.selectItemsGoods.length) {
        this.allStateSign = false
      }
    },

    loadMore() {
      if (this.onLineListened) return
      if (!this.nextPage) return
      this.pullDisabled = true
      this.$emit('loadMore')
      this.offlineHanler()
    },

    // 断网处理
    offlineHanler () {
      if (typeof window !== 'undefined' && !navigator.onLine) {
        this.onLineListened = true
        const handler = () => {
          this.onLineListened = false
          this.$emit('loadMore')
          window.removeEventListener('online', handler)
        }
        window.addEventListener('online', handler)
      }
    },

    updateSimilarPopupClass (key, val) {
      this.similarPopupClass[key] = val
      // this.hideFeedbackRecPopup() // similarItemAddRecMixin 中的方法
    },

    /* 长按商品 */
    handleLongClick({ item }) {
      if (!this.showSelect) return
      this.$emit('longClick') // 先触发编辑状态, 在赋值goodsid
      nextTick(() => {
        this.itemSelect({ item, selected: true })
      })
    },

    getExistGourpTotal() {
      return this.productList.reduce((t, item) => {
        if (item.inGroup) {
          t += 1
        }
        return t
      }, 0)
    },

    /* 选择商品 */ 
    itemSelect({ item, selected }) {
      this.allSelectTool[selected ? 'set' : 'delete'](item)
      this.updateSelectStatus()

      this.allStateSign = this.selectItemsGoods.length === this.productList.length - this.getExistGourpTotal()
      analysis.selectItemEventCenter(selected)
    },

    updateSelectStatus() {
      this.selectItemsGoods = this.allSelectTool.getGoodIds()
      if (this.selectItemsGoods.length >= this.selectLimitNum) {
        SToast(this.language.SHEIN_KEY_PWA_17413)
      }
    },

    handleAllSelect(isSelect) {
      if (!isSelect) {
        this.clearSelect()
      } else {
        analysis.selectAllEventCenter()
        this.selectAll()
      }
    },
    // 选中所有商品
    selectAll() {
      this.productList.forEach(item => {
        if (item.inGroup) return // 分组选择页: 在分组中，不进行选中。
        this.allSelectTool.set(item)
      })
      this.updateSelectStatus()
    },
    clearSelect() {
      this.allSelectTool.clear()
      this.updateSelectStatus()
      // 取消全选
      this.allStateSign && (this.allStateSign = false)
    },

    // 单个商品加组
    async handleAddBoard(item) {
      analysis.itemAddBorad(item.goods_id)
      const data = await this.$_WD({
        type: 6,
        params: {
          goodsIds: [item.goods_id],
        },
        callback: {
          skipGroupCallback: ({ groupId }) => {
            this.$router.push(`${langPath}/user/wishlist/groups/${groupId}`)
          }
        }
      })
      this.$emit('afterAddBorad', data)
    },
    // 批量加组
    async selectAddBorad() {
      const goodsIds = this.selectItemsGoods
      analysis.clickAddBoardEventCenter()
      if (this.config.callback?.batchBoard) { // 使用场景有：分组选择页
        this.config.callback.batchBoard({ goodsIds }, () => {
          this.clearSelect()
        })
        return
      }

      const data = await this.$_WD({
        type: 6,
        params: { goodsIds },
        callback: {
          skipGroupCallback: ({ groupId }) => {
            this.$router.push(`${langPath}/user/wishlist/groups/${groupId}`)
          }
        }
      })
      this.clearSelect()

      this.$emit('afterAddBorad', data)
    },

    async hanldeCopyItems() {
      if (!this.groupId) return
      analysis.clickCopyBoradEventCenter()
      await this.$_WD({
        type: 6, 
        page: 'group',
        params: {
          groupId: this.groupId,
          goodsIds: this.selectItemsGoods, // 商品数组
        },
        callback: {
          skipGroupCallback: ({ groupId }) => {
            this.$router.push(`${langPath}/user/wishlist/groups/${groupId}`)
          }
        }
      })
      this.clearSelect()
    },

    async clickDelete(item) {
      if (this.config.callback?.delete) { // 足迹页单独使用
        this.config.callback.delete(item)
        this.$refs[`WishProductItem_${item.goods_id}`]?.$refs?.[`productItem_${item.goods_id}`]?.handleDeleteOperation?.()
        return
      }

      await this.$_WD({
        type: 5,
        params: {
          groupId: this.groupId,
          goodsIds: [item.goods_id],
          wishlistIds: [item.wishlistId], // 收藏删除是用wishlistId
        },
      })
      analysis.deleteEventCenter({
        goods_id: item.goods_id,
        item_sold_out: item.isSoldOut,
        activity_from: this.config?.sa?.activity_from
      })
      this.$emit('afterDelete', item)
      if (this.$refs[`WishProductItem_${item.goods_id}`] && this.$refs[`WishProductItem_${item.goods_id}`].$refs[`productItem_${item.goods_id}`]) {
        this.$refs[`WishProductItem_${item.goods_id}`].$refs[`productItem_${item.goods_id}`]?.handleDeleteOperation?.()
      }
    },
    
    // 点击商品项事件
    clickItem(payload) {
      // 实时反馈用，参见实时反馈链路优化需求: pageId=1308184808
      let salePrice = payload?.salePrice?.usdAmount
      let discountRate = payload?.pretreatInfo?.discountInfo?.originDiscountRate
      this.getGoodsData({ goodsId: payload?.goods_id, cate_ids: payload?.cat_id, salePrice, discountRate })

      this.$emit('clickGoods') // 给半屏列表弹窗用
    },

    // 批量删除
    async deleteSelectItems() {
      const goodsIds = this.selectItemsGoods
      const wishlistIds = this.allSelectTool.getWishlistIds()
      const items = this.allSelectTool.getItems()

      if (this.groupId) {
        analysis.clickBatchDeleteEventCenter()
      }
      
      await this.$_WD({
        type: 5,
        params: {
          groupId: this.groupId,
          goodsIds,
          wishlistIds, // 收藏商品删除 用wishlistId
        },
      })
      this.clearSelect()
      analysis.batchDeleteEventCenter({
        activity_from: this.config?.sa?.activity_from,
        goods_list: items
      })
      this.$emit('afterBatchDelete', items)
    },

    // 快加车 
    async handleOpenQuickAdd({ item = {}, index, target, isRecommend = true, activityFrom }) {
      // 实时反馈用，参见实时反馈链路优化需求: pageId=1308184808
      let salePrice = item?.salePrice?.usdAmount
      let discountRate = item?.pretreatInfo?.discountInfo?.originDiscountRate
      this.getGoodsData({ goodsId: item?.goods_id, cate_ids: item?.cat_id, salePrice, discountRate })

      const imgRatio = await getImageRatio(item.goods_img)
      const { goods_id, mall_code, goods_img } = item
      // 是否开启新的快加车
      // const status = canUseNewQuickAdd('list')
      // if(status) {
      getQuickAddCartInstance().open({
        goods_id,
        mallCode: mall_code.toString(),
      }, {
        extendedParam: {
          mainImg: goods_img,
          imgRatio: imgRatio,
        },
        featureConfig: {
          showEstimatedPrice: !item.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.cartHideEstimatedPrice && this.config?.showItemV3, // 新商卡中加车弹窗中的商品展示到手价, 在原价的右侧多一个到手价
        },
        analysisConfig: {
          index,
          sourceTarget: target,
          code: this.config.sa?.code || this.config.sa?.activity_from || 'wishlist',
          from: this.config.sa?.activity_from,
          sa: activityFrom ? Object.assign({}, this.config.sa, { activity_from: activityFrom }) : this.config.sa,
        },
        salesAttrConfig: {
          selectedAttrSkuCode: item.sku_code ? item.sku_code : '',
          selectedAttrIndex: +item.attrValueId ? { 87: { attr_value_id: item.attrValueId } } : {},
        },
        detailUrlExtendParam: {
          showFeedbackRec: true, // FR-14861和商详约定
        },
        callbacks: {
          onDrawerStatusChange: async active => {
            if (this.showFeedbackRecommend) {
              // feedbackRecMixin
              this.handleWishFeedbackRecInfo({
                active,
                clickDetails: QuickAddCartState?.isClickDetails,
                goodsId: goods_id,
                addBagStatus: QuickAddCartState?.addCartStatus,
                addWishStatus: QuickAddCartState?.wishStatus,
                isRecommend,
              })
            } else {
              // 是否售罄, 售罄就一定不会走同类推荐
              let fromSimilarItemAdd = Number(item?.is_on_sale) === 0 || Number(item?.stock) === 0
              // similarItemAddRecMixin
              !this.disableSimilarAdd && this.handleSimilarRecInfo({ active, clickDetails: QuickAddCartState?.isClickDetails, goodsId: goods_id, addBagStatus: QuickAddCartState?.addCartStatus, addWishStatus: QuickAddCartState?.wishStatus, isRecommend: true, fromSimilarItemAdd })
            }
          },
          onAddCartFinished: (data) => {
            if (!data?.isSuccess) return
            this.$emit('dropCartBagImg', target)
          }
        },
        plugins: [plugins.syncGlobalDataPlugin(),]
      })

      //   return
      // }

      // this.quickAddInstance = await this.$quickAdd.open({
      //   goods_id,
      //   mallCode: mall_code.toString(),
      //   mainImg: goods_img,
      //   index,
      //   imgRatio,
      //   showEstimatedPrice: !item.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.cartHideEstimatedPrice && this.config?.showItemV3, // 新商卡中加车弹窗中的商品展示到手价, 在原价的右侧多一个到手价
      //   selectedAttrSkuCode: item.sku_code ? item.sku_code : '',
      //   selectedAttrIndex: +item.attrValueId ? { 87: { attr_value_id: item.attrValueId } } : {},
      //   showFeedbackRec: true, // FR-14861和商详约定
      //   needCartTagTipsUpdate: false, // TR-17838增加, 不需要快加车内部去触发利诱相关的update方法
      //   analysisConfig: {
      //     sourceTarget: target,
      //     code: this.config.sa?.code || this.config.sa?.activity_from || 'wishlist',
      //     from: this.config.sa?.activity_from,
      //     sa: activityFrom ? Object.assign({}, this.config.sa, { activity_from: activityFrom }) : this.config.sa,
      //   },
      //   clickCallBack: {
      //     isActive: (active, goodsId, clickDetails, addBagStatus, addWishStatus) => {
      //       if (this.showFeedbackRecommend) {
      //         // feedbackRecMixin
      //         this.handleWishFeedbackRecInfo({
      //           active,
      //           clickDetails,
      //           goodsId,
      //           addBagStatus,
      //           addWishStatus,
      //           isRecommend,
      //         })
      //       } else {
      //         // 是否售罄, 售罄就一定不会走同类推荐
      //         let fromSimilarItemAdd = Number(item?.is_on_sale) === 0 || Number(item?.stock) === 0
      //         // similarItemAddRecMixin
      //         !this.disableSimilarAdd && this.handleSimilarRecInfo({ active, clickDetails, goodsId, addBagStatus, addWishStatus, isRecommend: true, fromSimilarItemAdd })
      //       }

      //     },
      //   }
      // })
      // if (this.quickAddInstance) {
      //   this.quickAddInstance?.$on('addToBagSuccess', this.debounceIt)
      // }
    },
    // debounceIt: debounce({
    //   func: function ({ target }) {
    //     this.$emit('dropCartBagImg', target)
    //     this.quickAddInstance.$off('addToBagSuccess')
    //   },
    //   wait: 20,
    // }),
    clickReset() {
      this.$emit('reset')
    },
    // 是否优先展示实时反馈推荐
    getFeedbackRecommend() {
      if(gbCommonInfo.WISHLIST_ABT_COMPLETE === 'on') {
        this.showFeedbackRecommend = false
        return
      }
      const { WishlistReco } = this.listAbtResult
      const order = WishlistReco?.p?.ShowWishlistRecoorder || '' // 实时反馈推荐、同类对比两者的优先级
      const priorityArr = order?.split?.(',') || []
      this.showFeedbackRecommend = priorityArr.findIndex(i => i == 'WishlistReco') == 0
    },
    // 实时反馈推荐ccc的中间层
    async getFeedbackRecConfig() {
      const result = await schttp({ url: '/api/user/wishlist_v2/feedbackRec' })
      const { feedbackRecConfig } = result
      this.feedbackRecConfig = feedbackRecConfig?.feeReco || {}
    },

    updateData() {
      const { ymal, similar, goodsId } = getFeedbackRecInSession() || {}
      let data = getFeedbackRecDataInSession(goodsId)
      // 针对实时反馈的去重逻辑
      if(data && (ymal || similar)) {
        const ymalArray = ymal ? ymal.split(',') : []
        const similarArray = similar ? similar.split(',') : []
        const filterSet = new Set([...ymalArray, ...similarArray])
        data = data.filter(item=> !filterSet.has(`${item?.goods_id}`))?.slice(0, 10)
      }
      this.recommendPopupGood = data
      setFeedbackRecDataInSession(goodsId, this.recommendPopupGood)
    },

    // 大于ccc配置的最多显示次数
    hitCccMostTimes() {
      const { path } = this.$route || {}
      const suffix = path
      const key = `show_feedback_rec_times_${suffix}`
      const eachListShowTimes = sessionStorage.getItem(key) || 0
      const mostTimes = this.feedbackRecConfig?.mostOccurrences // 实时反馈弹窗最多出现的次数

      return eachListShowTimes >= mostTimes
    },

    // 请求反馈弹窗内容体数据，在这里只有实时反馈一种类型
    async getGoodsData(arg) {
      if(gbCommonInfo.WISHLIST_ABT_COMPLETE === 'on') {
        return
      }
      const { WishlistReco } = this.listAbtResult
      if(!WishlistReco?.p?.ShowWishlistReco && !this.showFeedbackRecommend) {
        return 
      }
      // 超过打开CCC配置次数 或者 24小时内用户主动触发了两次关闭（减少非必要请求）
      if(this.hitCccMostTimes() || !showByLocalStorage()) {
        return
      }

      // 客户端缓存请求逻辑获取数据
      if(arg.goodsId) {
        let data = getFeedbackRecDataInSession(arg.goodsId)
        if(data && data?.length) {
          this.recommendPopupGood = data ?? []
          return
        }
        
        // 实时反馈类型
        let { triggerEvent = '', productImpressions = '' } = this.feedbackRecConfig
        this.recommendPopupGood = await getFeedBackListGoods({ ...arg, listAbtResult: this.listAbtResult, triggerEvent: triggerEvent, scene_id: 156, productLimit: productImpressions }) ?? []
        setFeedbackRecDataInSession(arg.goodsId, this.recommendPopupGood)
      }
    },
  },
})
</script>

<style lang="less">
// 针对半屏列表 新商卡样式
/* stylelint-disable-next-line */
.recommend-drawer .sui-drawer__body .wish-products-comp-drawer {
  /* stylelint-disable-next-line declaration-no-important */
  padding: 1.28rem .16rem 0 .16rem !important;
}

.wish-list-comp {
  &__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__loading {
    padding-bottom: 0.32rem;
  }
  &__edit {
    padding-bottom: 1.28rem + 0.32rem;
  }
  &__itemMore {
    margin-bottom: 0.64rem;
  }
  &__item-fill {
    width: 2.9733rem;
  }
  .waterfall {
    width: 100%;
  }
  &__show-similar-popup {
    .product-item__bottom-info_soldout {
      .product-item__prices_soldout {
        opacity: 1;
        /* stylelint-disable-next-line */
        .product-item__normal-price .normal-price{
          opacity: 0.4;
        }
        /* stylelint-disable-next-line */
        .sui_icon_more_20px {
          opacity: 1;
        }
      }
    }
    .product-item__soldout-content { 
      transform: translate3d(-50%,0, 0);
      left: 50%;
    }
    .product-card__soldout-content, .product-item__soldout-content {
      top: 0.56rem;
      .operation-btns__delete {
        visibility: hidden;
        border: 0.5px solid #BBB;
        /* stylelint-disable-next-line */
        .sui-button-common {
          background:  #FFF;
          font-size: 14px;
          font-weight: 700;
          color: #222;
          border: none;
        }
      }
      .icon-SOLDOUT {
        font-size: 32px;
        height: 0.8533rem;
        line-height: 0.8533rem;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
.wish-list-comp__show-delete {
  .product-card__soldout-content, .product-item__soldout-content {
    visibility: hidden;
    .operation-btns__delete {
      visibility: visible
    }
  }
}
.wish-list-comp__show-similar-popup.wish-list-comp__crop {
  .product-card__soldout-content {
    top: 35%;
  }
  .product-item__soldout-content {
    top: 0;
  }
}
</style>
